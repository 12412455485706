// Styles for page-specific tapestry components
// Ref: https://planningcenter.github.io/tapestry-react/page

export default {
  pageBody: {
    backgroundColor: "inherit",
    paddingTop: "14px",
  },
  pageButton: {
    theme: "pageHeader",
    variant: "fill",
    borderRadius: "4px",
    borderWidth: "1px",
    fontWeight: 400,
    lineHeight: "24px",
    paddingVertical: "3px",
    paddingHorizontal: 2,
  },
  pageTitle: {
    lineHeight: "32px",
  },
}
