import React from "react"
import { node } from "prop-types"
import { ThemeProvider } from "@planningcenter/tapestry-react"
import theme from "theme"

PeopleThemeProvider.propTypes = {
  children: node.isRequired,
}

export default function PeopleThemeProvider({ children, ...restProps }) {
  return (
    <ThemeProvider theme={theme} {...restProps}>
      {children}
    </ThemeProvider>
  )
}
