import buttons from "./buttons"
import colors from "./colors"
import icons from "./icons"
import tooltips from "./tooltips"
import page from "./page"

// Custom theme object for tapestry-react theme provider
// https://planningcenter.github.io/tapestry-react/theming

export default {
  button: { ...buttons },
  colors: { ...colors },
  icons: { ...icons },
  id: "people",
  ...page,
  tooltip: { ...tooltips },
}
