import pcoURL from "@planningcenter/url"

// Flattens the params object in order to send it to the endpoint.
// Recursively iterates through an object's values. If that value is
// itself an object, it recurses. If it is a simple type, it will
// return a simple query param.
//
// Examples:
//
//     flattenParams({ dad: "Geo", kids: { daughter: "Olivia", son1: "Max", son2: "Calvin" } })
//     // => dad=Geo&kids[daughter]=Olivia&kids[son1]=Max&kids[son2]=Calvin
export const flattenParams = ([parentKey, obj]) => {
  if (typeof obj !== "object") return `${parentKey}=${obj}`

  return Object.entries(obj)
    .map(
      ([key, val]) =>
        `${parentKey}[${key}]=${
          typeof val === "object" ? flattenParams(key, val) : val
        }`
    )
    .join("&")
}

export const constructQueryStringFromParams = params =>
  Object.entries(params)
    .filter(([_key, val]) => val) // eslint-disable-line no-unused-vars
    .map(flattenParams)
    .join("&")

export default class API {
  static async get(
    path,
    params = {},
    options = {
      signal: new AbortController().signal,
    }
  ) {
    let url = prepareURL(path, options.apiBase)
    if (Object.keys(params).length > 0) {
      const paramsString = constructQueryStringFromParams(params)
      url = encodeURI(`${url}?${paramsString}`)
    }

    return fetch(url, {
      method: "GET",
      credentials: "include",
      signal: options.signal,
      headers: {
        "X-PCO-API-Version": "latest",
        "Content-Type": "application/json",
      },
    }).then(resp => resp.json())
  }

  // There are two ways to interact with this function:
  //
  // 1. Pass a callback function. This will be called after every
  //    request is complete and the callback function will be passed the
  //    server's response.
  //
  // 2. Provide a `.then` promise resolver on the original call. This
  //    will be resolved with the aggregate of all the
  //    recursively-fetched results from the server. Note that this
  //    object only contains `data` and `included` keys (meaning `meta`
  //    and `links` are left out).
  //
  // These two approaches can be combined. For example, you can
  // progressively build up your component's state as the results are
  // being returned from the server while also knowing when all the
  // results have been fetched. For example:
  //
  //     getAll("/people/v2/people", { per_page: 100 }, resp => {
  //       this.setState(({ people }) => {
  //         return { people: [...people, resp.data] }
  //       })
  //     }).then(aggregate => {
  //       this.setState({ loading: false })
  //       console.log("all people", aggregate.data)
  //     })
  //
  // If you need to use the callback, you must pass a params object.
  static async getAll(path, params = {}, cb, options = {}) {
    return getAllWithRecursion(
      path,
      params,
      { data: [], included: [] },
      cb,
      options
    )
  }

  static async patch(path, params = {}, options = {}) {
    return fetch(prepareURL(path, options.apiBase), {
      method: "PATCH",
      credentials: "include",
      headers: {
        "X-CSRF-Token": $.rails.csrfToken(),
        "X-PCO-API-Version": "latest",
        "Content-Type": "application/json",
        ...options.headers,
      },
      body: JSON.stringify(params),
    }).then(resp => resp.json())
  }

  static async post(path, params = {}, options = {}) {
    return fetch(prepareURL(path, options.apiBase), {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": $.rails.csrfToken(),
        "X-PCO-API-Version": "latest",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then(resp => resp.json())
  }

  static async delete(path, options = {}) {
    return fetch(prepareURL(path, options.apiBase), {
      method: "DELETE",
      credentials: "include",
      headers: {
        "X-CSRF-Token": $.rails.csrfToken(),
        "X-PCO-API-Version": "latest",
        "Content-Type": "application/json",
      },
    })
  }
}

async function getAllWithRecursion(path, params, aggregate, cb, options) {
  return API.get(path, params, options).then(resp => {
    if (resp.errors) throw resp.errors
    if (cb) cb(resp)

    aggregate = {
      data: [...aggregate.data, ...resp.data],
      included: [...aggregate.included, ...resp.included],
    }

    if (resp.links && resp.links.next) {
      const nextPath = resp.links.next
        .replace(new RegExp(options.apiBase, "i"), "")
        .replace(new RegExp(apiBase, "i"), "")
      return getAllWithRecursion(nextPath, {}, aggregate, cb, options)
    } else {
      return aggregate
    }
  })
}

const apiBase = pcoURL(window.railsEnv)("api")
const prepareURL = (path, apiBaseURL = apiBase) => {
  path = path.replace(new RegExp(apiBaseURL, "i"), "")
  return `${apiBaseURL}${path.startsWith("/") ? path : `/${path}`}`
}
