import * as churchCenter from "@planningcenter/icons/paths/church-center"
import * as logomark from "@planningcenter/icons/paths/logomark"
import * as people from "@planningcenter/icons/paths/people"
import * as services from "@planningcenter/icons/paths/services"
import * as api from "@planningcenter/icons/paths/api"

export default {
  churchCenter,
  logomark,
  people,
  services,
  api,
}
