import React from "react"

const Nl2Br = ({ children }) => {
  const string = children || ""
  return (
    <span className="ww-bw wb-bw hy-a">
      {string.split("\n").map((item, key) => (
        <span key={key}>
          {item}
          <br />
        </span>
      ))}
    </span>
  )
}

export default Nl2Br
